import React from 'react';
import styles from './modal.scss';
import { Modal } from 'antd';

class NewModal extends React.Component {
    handleClose = (e) => {
        const { cancelButtonProps = {} } = this.props;
        if (cancelButtonProps.disabled) return;
        e.stopPropagation();
        this.props.onCancel(e);
    };
    render() {
        const {
            footer,
            className,
            closable = true,
            wrapClassName,
            bodyStyle = {},
            ...moreProps
        } = this.props;
        if (!bodyStyle?.height) {
            // bodyStyle.flex = 1;
        }
        const propsJson = {
            className: [
                styles.newModal,
                moreProps?.width <= 500 && styles.minModal,
                className,
            ].join(' '),
            centered: true,
            destroyOnClose: true,
            maskClosable: false,
            closable,
            closeIcon: (
                <img
                    src={require(`@/assets/images/common/close${this.props?.title ? '' : 2}.svg`)}
                />
            ),
            footer,
            wrapClassName: `${wrapClassName}`,
            bodyStyle,
            onOk: (e) => {
                e.stopPropagation();
                this.props.onOk(e);
            },
            onCancel: this.handleClose,
            ...moreProps,
            // width: 'auto',
        };

        return (
            <Modal {...this.props} {...propsJson}>
                {this.props.children}
            </Modal>
        );
    }
}
export default NewModal;
