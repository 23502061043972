import _ from 'lodash';
import {
    getLimitBalance,
    getReportBalance,
    getCaseBalance,
    getApkBalance,
    getApkReportBalance,
} from '@/api/balance';
import { getUserInfo } from '@/api/user';
import { setLocalStorage } from '@/utils/local';
export default {
    namespace: 'user',

    state: {
        balance: {}, //网站上传额度
        reportBalance: {}, //网站报告查看额度
        // caseBalance: {}, //案情上传额度
        apkBalance: {}, //apk上传额度
        apkReportBalance: {}, //apk报告额度
        userInfo: {},
    },
    effects: {
        // 当日 - 网站研判额度（网站上传文件）
        *getLimitBalance(payload, { put, call }) {
            const { data } = yield call(getLimitBalance);
            yield put({ type: 'setLimitBalance', data });
            return data;
        },
        // 当日 - 网站报告额度
        *getReportBalance(payload, { put, call }) {
            const { data } = yield call(getReportBalance);
            yield put({ type: 'setReportBalance', data });
            return data;
        },
        // // 当日 - 案情额度
        // *getCaseBalance(payload, { put, call }) {
        //     const { data } = yield call(getCaseBalance);
        //     yield put({ type: 'setCaseBalance', data });
        //     return data;
        // },
        // 当日 - Apk上传文件额度
        *getApkBalance(payload, { put, call }) {
            const { data } = yield call(getApkBalance);
            yield put({ type: 'setApkBalance', data });
            return data;
        },
        // 当日 - Apk报告额度
        *getApkReportBalance(payload, { put, call }) {
            const { data } = yield call(getApkReportBalance);
            yield put({ type: 'setApkReportBalance', data });
            return data;
        },
        *getUserInfo(payload, { put, call }) {
            const { data } = yield call(getUserInfo);
            yield put({ type: 'setUserInfo', data });
            return data;
        },
    },
    reducers: {
        setLimitBalance(state, { data }) {
            const newState = _.cloneDeep(state);
            newState.balance = data;
            return { ...newState };
        },
        setReportBalance(state, { data }) {
            const newState = _.cloneDeep(state);
            newState.reportBalance = data;
            return { ...newState };
        },
        setCaseBalance(state, { data }) {
            const newState = _.cloneDeep(state);
            newState.caseBalance = data;
            return { ...newState };
        },
        setApkBalance(state, { data }) {
            const newState = _.cloneDeep(state);
            newState.apkBalance = data;
            return { ...newState };
        },
        setApkReportBalance(state, { data }) {
            const newState = _.cloneDeep(state);
            newState.apkReportBalance = data;
            return { ...newState };
        },
        setUserInfo(state, { data }) {
            let newState = { ...state };
            newState.userInfo = { ...data };
            setLocalStorage('userInfo', newState);
            return newState;
        },
    },
};
