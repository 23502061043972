import { createInstance } from '@jonkoops/matomo-tracker-react';

export const matomoInstance = createInstance({
    urlBase: 'https://analytics.tools.nosugar.tech',
    siteId: location.origin === 'https://dagou.nosugar.tech' ? 2 : 3,
    linkTracking: true,
});
export const matomoSearch = (data = {}) => {
    matomoInstance.trackSiteSearch(data);
};

export const matomoTrackEvent = (data = {}) => {
    matomoInstance.trackEvent({
        category: '点击按钮',
        action: '点击',
        value: 1,
        ...data,
    });
};
