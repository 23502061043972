import { http } from '@/utils/httpApi';
// -------------------登录获取用户信息 -------------
export const getUserInfo = (params) => http.get('/api/user/profile', params);
// 获取用户基本信息
export const getUserBasic = (params) => http.get('/api/user/basic', params);

// ----------------------权限范围 ----------------------
// 获取权限说明
export const getRoleExplain = (params) => http.get('/api/item/role', params);

// 获取权限界面显示
export const getRoleShow = (params) => http.get('/api/item/show', params);

// 新建权限组
export const createRole = (params) => http.post('/api/role/group/add', params);

// 获取权限列表
export const getRoleList = (params) => http.post('/api/role/group/list', params);

// 删除某一权限组
export const deleteRole = (params) => http.post('/api/role/group/delete', params);

// 编辑某一权限组
export const updateRole = (params) => http.post('/api/role/group/update', params);

// 获取某一权限设定
export const getRoleById = (params) => http.post('/api/role/group/details', params);

// --------------------------用户分组管理----------------------------
// 新建用户分组
export const createUserGroup = (params) => http.post('/api/user/group/add', params);

// 获取用户分组列表
export const getUserGroupList = (params) => http.post('/api/user/group/list', params);

// 删除某一用户分组
export const deleteUserGroup = (params) => http.post('/api/user/group/delete', params);

// 编辑某一用户分组
export const updateUserGroup = (params) => http.post('/api/user/group/update', params);

// 获取权限列表 - 无分页，可根据名称查询
export const getRoles = (params) => http.get('/api/role/group/list', params);

// ----------------------------用户管理---------------------------
// 新建用户
export const createUser = (params) => http.post('/api/user/add', params);

// 获取用户列表
export const getUserList = (params) => http.post('/api/user/list', params);

// 删除某一用户
export const deleteUser = (params) => http.get('/api/user/delete', params);

// 编辑某一用户
export const updateUser = (params) => http.post('/api/user/update', params);

// 启用状态
export const statusActive = (params) => http.post('/api/user/activate', params);

// 获取用户的可选权限
export const getUserRoleById = (params) => http.post('/api/user/prepare', params);

// 获取用户分组 - 无分页
export const getUserGroups = (params) => http.get('/api/user/group/list', params);

// -------------------诈骗类型管理------------------------
// 添加诈骗主类
export const addFraudClass = (params) => http.post('/api/fraud_class/add', params);

// 编辑诈骗主类
export const editFraudClass = (params) => http.post('/api/fraud_class/update', params);

// 添加诈骗类型
export const addFraudType = (params) => http.post('/api/fraud_type/add', params);

// 编辑诈骗类型
export const editFraudType = (params) => http.post('/api/fraud_type/update', params);

// 删除诈骗类型
export const deleteFraudType = (params) => http.post('/api/fraud_type/delete', params);

// 启用状态
export const activatedFraudType = (params) => http.post('/api/fraud_type/activated', params);

// 删除诈骗主类
export const deleteFraudClass = (params) => http.post('/api/fraud_class/delete', params);

// --------------------个人中心----------------------------
export const getLoginLogs = (params) => http.post('/api/login/log/list', params);
