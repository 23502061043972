export default {
    namespace: 'globalRun',

    state: {
        loading: false,
    },
    subscriptions: {
        // 全局监听路由
        setup({ dispatch, history }) {
            history.listen((location) => {
                dispatch({ type: 'redirect', location });
            });
        },
    },
    effects: {
        *redirect() {
            yield;
        },
    },
    reducers: {
        SET_LOADING(state, { loading }) {
            let newState = { ...state };
            newState.loading = loading;
            return newState;
        },
    },
};
