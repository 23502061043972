import * as Sentry from '@sentry/react';
import { setVisitorId } from './utils/common';
import { history } from 'umi';
import { setLocalStorage } from './utils/local';
Sentry.init({
    dsn: process.env.dsn,
    release: process.env.release,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    //不应该发送到Sentry的错误URL相匹配的字符串或正则表达式
    denyUrls: [
        'https://g.alicdn.com/dingding/dinglogin/0.0.5/ddLogin.js',
        '/umi.',
        '/vendors.',
        'swagger-ui-bundle.js',
        '/node_modules',
    ],
    // tracesSampleRate: 0.05,
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
        /ResizeObserver loop limit exceeded/i,
        'ResizeObserver loop completed with undelivered notifications',
    ],
});
Sentry.configureScope((scope: any) => {
    scope.addEventProcessor((event: any) => {
        let { value = '', type = '' } = event?.exception?.values?.[0] || {};
        if (['ResizeObserver loop limit exceeded', 'Network Error'].includes(value)) {
            return null;
        }
        if (type === 'UnhandledRejection') {
            return null;
        }
        return event;
    });
});
let { ime, token } = history?.location?.query;
if (ime) {
    setLocalStorage('ime', ime);
    setLocalStorage('token', token);
}
setVisitorId();
