import { getRoleShow } from '@/api/user';
import { setLocalStorage, getLocalStorage } from '@/utils/local';
export default {
    namespace: 'role',

    state: {
        roleList: Object.values(getLocalStorage('roles') || {}),
        roleObj: getLocalStorage('roles') || {},
    },
    effects: {
        // 可显示的功能板块说明
        *getRoleShow(payload, { put, call }) {
            const { data } = yield call(getRoleShow);
            yield put({ type: 'setRoleShow', data });
        },
    },
    reducers: {
        setRoleShow(state, { data }) {
            let newState = { ...state };
            newState.roleList = data;
            let roleObj = {};
            data.forEach((item) => {
                roleObj[item.name] = item;
            });
            newState.roleObj = roleObj;
            setLocalStorage('roles', roleObj);
            return newState;
        },
    },
};
