import { http } from '@/utils/httpApi';
// 当日 - 网站研判额度
export const getLimitBalance = (params, config = {}) =>
    http.get('/api/balance/analyze/current', params, config);

// 当日 - 网站报告额度
export const getReportBalance = (params) => http.get('/api/balance/report/current', params);

// 当日 - 线索额度
export const getClueBalance = (params, config = {}) =>
    http.get('/api/balance/clue/current', params, config);

// 当日 - 案情额度
export const getCaseBalance = (params, config = {}) =>
    http.get('/api/balance/case/current', params, config);

// 当日 - APk上传额度
export const getApkBalance = (params, config = {}) =>
    http.get('/api/balance/apk/current', params, config);

// 当日 - APk报告额度
export const getApkReportBalance = (params) => http.get('/api/balance/apk_report/current', params);

// 网站研判额度记录 - 上传历史记录
export const getAnalyzeBalanceList = (params) => http.post('/api/balance/analyze/history', params);

// 报告查询记录
export const getReportBalanceList = (params) => http.post('/api/balance/report/history', params);

// 线索查询记录
export const getClueBalanceList = (params) => http.post('/api/balance/clue/history', params);

// 案情查询记录
export const getCaseBalanceList = (params) => http.post('/api/balance/case/history', params);

// APK查询记录 - 上传文件历史记录
export const getAPKBalanceList = (params) => http.post('/api/balance/apk/history', params);

// APK报告查询记录
export const getAPKReportBalanceList = (params) =>
    http.post('/api/balance/apk_report/history', params);

// 获取报告是否可浏览-关联每日报告查询次数
export const getAccessReportByHash = (params) => http.get('/api/report/operation/limit', params);
