import { useState } from 'react';

export default function useCluster() {
    const [retract, setretract] = useState(false); //侧边收起
    const [isSearch, setisSearch] = useState(false); //是否搜索
    const [targetType, settargetType] = useState({}); //目标点类型
    const [ConditionData, setConditionData] = useState([]); //有效条件
    const [voidNodes, setvoidNodes] = useState([]); //无效条件节点
    const [searchLoadding, setsearchLoadding] = useState(false);
    //重置
    const reset = () => {
        settargetType({});
        setConditionData([]);
        setvoidNodes([]);
        setisSearch(false);
        setretract(false);
    };

    //设置无效点
    const setVoid = (record) => {
        setvoidNodes((pre) => [...pre, record]);
    };

    //恢复无效点
    const recoveryNode = (record) => {
        setvoidNodes((pre) => pre.filter((v) => v.id !== record.id));
    };
    return {
        retract,
        setretract,
        isSearch,
        setisSearch,
        voidNodes,
        setVoid,
        recoveryNode,
        targetType,
        settargetType,
        ConditionData,
        setConditionData,
        reset,
        searchLoadding,
        setsearchLoadding,
    };
}
