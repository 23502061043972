import { useState, useMemo } from 'react';
import { formatValue } from '@/utils/common';
const limit = 50;
// 存储测验数据
const TestModel = () => {
    const [inputValue, setInputValue] = useState('');

    const inputValueArr = useMemo(() => {
        const dataArr = formatValue(inputValue || '') || [];
        return dataArr.slice(0, limit);
    }, [inputValue]);

    return {
        inputValue,
        setInputValue,
        inputValueArr,
        limit,
    };
};
export default TestModel;
