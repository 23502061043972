import { useState } from 'react';

export default function SearchData() {
    const [dimSearchList, setdimSearchList] = useState(null);

    return {
        dimSearchList,
        setdimSearchList,
    };
}
