const { getLocalStorage } = require('@/utils/local');
import { getUserBasic } from './api/user';
import { matomoInstance } from './utils/matomoInstance';
const { message } = require('antd');
import * as Sentry from '@sentry/react';
import { level } from '@/pages/setting/common';
let configRoutes: any = [];
// 获取.umirc中的router
export function patchRoutes({ routes }: any) {
    configRoutes = routes;
}
// 接口获取路由权限
export async function getInitialState() {
    const userInfo = getLocalStorage('userInfo') || {};
    const pageRoles = userInfo.shows ? userInfo.shows : [];
    const roles = userInfo.roles ? userInfo.roles : [];
    let data = [...roles, ...pageRoles];
    return { data, configRoutes };
}

message.config({
    top: 60,
    duration: 3,
    maxCount: 2,
});

let isSetUserID = false;
export function onRouteChange({ location }: any) {
    const { username, realname, id, level: levelNum } = getLocalStorage('userInfo') || {};

    if (username && !isSetUserID) {
        matomoInstance.pushInstruction('setUserId', `${username}(${realname})`);
        getUserBasic({ user_id: id }).then((res) => {
            let { template_name } = res.data;
            matomoInstance.pushInstruction(
                'setCustomVariable',
                '1',
                '账号版本',
                level[levelNum],
                'visit',
            );
            matomoInstance.pushInstruction(
                'setCustomVariable',
                '2',
                '模板名称',
                template_name,
                'visit',
            );
        });
        Sentry.setUser({
            id: id,
            username: `${username}(${realname})`,
        });
        isSetUserID = true;
    }
    matomoInstance?.trackPageView({
        href: location.pathname,
    });
}
// function setRem() {
//     const baseSize = 16;
//     // 1920为设计稿页面宽度，1366为最小宽度
//     const scale = Math.max(document.documentElement.clientWidth, 1366) / 1920;
//     // 设置页面根节点字体大小
//     document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px';
// }
// 初始化
// setRem();
// window.onresize = function () {
//     setRem();
// };
