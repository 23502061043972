/**
 * 使用自定义的useCommonProblem Hook来管理常见问题的显示状态。
 *
 * @returns {Object} 返回一个包含isShow状态和setisShow更新函数的对象。
 */
import { useState } from 'react';
export default function useCommonProblem() {
    // 使用useState Hook来初始化和管理isShow状态
    const [isShow, setisShow] = useState(false);
    return { isShow, setisShow };
}
