import { message } from 'antd';
import axios from './axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { getLocalStorage, setLocalStorage } from './local';
import { matomoTrackEvent } from './matomoInstance';
export const addFormat = /[\n|,|，|(,\n)|(，\n)]/;

export const phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;

export const telephoneReg = /^(\d{3,4})?(-)?\d{7,8}$/;

//字母与数字或纯字母组合，可输入特殊字符（~!@#$%^&*）
export const passwordReg = /^(?!\d+$)(?![~!@#$%^&*]+$)[\da-zA-Z_~!@#$%^&*]{8,30}$/;

//字母与数字或纯字母3~20
export const usernameReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{3,20}|[A-Za-z]{3,20}$/;

// 不包含特殊字符(除_，且下划线不可在首尾出现)
export const notSpecialCharactersReg = /^(?!_)(?!.*?_$)[a-zA-Z0-9_\u4e00-\u9fa5]+$/;

export const idCardReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

export const bankCardReg = /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/;

// nosugartech邮箱
export const emailReg = /^[a-zA-Z0-9_-]+@nosugartech\.com$/;

// 邮箱
export const emailRegAll = /^([A-Za-z0-9_\-\\.])+@([A-Za-z0-9_\-\\.])+\.([A-Za-z]{2,4})$/;

export const commonUrlRegFn = (agree = '') => {
    return (
        `^((https|http${agree})://)?` + //(https或http或socks5):// 可有可无
        "(([\\w_!~*'()\\.&=+$%-]+: )?[\\w_!~*'()\\.&=+$%-]+@)?" + //ftp的user@ 可有可无
        '(([0-9]{1,3}\\.){3}[0-9]{1,3}' + // IP形式的URL- 3位数字.3位数字.3位数字.3位数字
        '|' + // 允许IP和DOMAIN（域名）
        '(localhost)|' + //匹配localhost
        "([\\w_!~*'()-]+\\.)*" + // 域名- 至少一个[英文或数字_!~*\'()-]加上.
        '\\w+\\.' + // 一级域名 -英文或数字 加上.
        '[a-zA-Z]{1,6})' + // 顶级域名- 1-6位英文
        '(:[0-9]{1,5})?' + // 端口- :80 ,1-5位数字
        '((/?)|' + // url无参数结尾 - 斜杆或这没有
        "(/[\\w_!~*'()\\.;?:@&=+$,%#-]+)+/?)$"
    ); //请求参数结尾- 英文或数字和[]内的各种字符
};

// 目标url
export const httpUrlReg = '(https|http)://';
export const urlReg = /^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/;
export const IPAllReg =
    /(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)\.(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)\.(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)\.(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)/;

// 代理url
export const proxyUrlReg = commonUrlRegFn('|socks5');

// ip格式：只满足xxx.xxx.xxx.xxx 类型的数字
export const ipReg = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;

export const ipv4Reg =
    /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;

export const ipv6Reg =
    /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/gm;
/**
 * 列表页的分页
 * @param {Object} pagi
 * @description 如果在redux中存储了上一次分页信息，可以参数的形式传进来
 * @description 如果只是this.state中存放的，就可不用传参数
 * */

export const paging = (pagi) => {
    return {
        current: 1,
        pageSize: 12,
        total: 0,
        showQuickJumper: true,
        showSizeChanger: false,
        showTotal: (total) => `▶   共 ${total} 条 `,
        ...pagi,
    };
};
// 生成uuid
export const guid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};
/**
 * 换算百分比
 * @param {Number} num 默认类似0.12845进行换算 = 0.12
 * @description 不四舍五入，向下取整，有小数则保留两位小数
 */
export const getPercentage = (num) => Math.floor(num * 10000) / 100;

// 字符串转数组，去重去空字符去前后空格(isDeduplication是否去重)
export const formatValue = (v, format = /(\n)/g, isDeduplication = true) => {
    if (!v) return;
    const arr = v.split(format);
    const newSetArr = isDeduplication ? [...new Set(arr)] : arr;
    const newArr = newSetArr?.map((i) => i?.trim())?.filter((item) => item);
    return newArr;
};

// 中文转运算符
export const operatorCultural = (type) => {
    let operator;
    switch (type) {
        case '等于':
            operator = '==';
            break;
        case '不等于':
            operator = '!=';
            break;
        case '小于':
            operator = '<';
            break;
        case '大于':
            operator = '>';
            break;
        case '小于或等于':
            operator = '<=';
            break;
        case '大于或等于':
            operator = '>=';
            break;
        case '包含':
            operator = 'contains';
            break;
        case '不包含':
            operator = '!contains';
            break;
        default:
            operator = type;
    }
    return operator;
};
/**
 * 合并多层对象
 * @param {*object} target
 * @param {*object} sources
 */
export const mergeObj = (target = {}, sources = {}) => {
    let obj = target;
    const isHaveTarget = JSON.stringify(target) !== '{}';
    const isHaveSources = JSON.stringify(sources) !== '{}';
    // 情况1：都为空对象或某一个为空对象
    if (!isHaveTarget && isHaveSources) {
        return sources;
    } else if (isHaveTarget && !isHaveSources) {
        return target;
    } else if (!isHaveTarget && !isHaveSources) {
        return {};
    }
    // 情况2：两都不为空对象
    for (let key in sources) {
        // 如果target也存在 那就再次合并
        // eslint-disable-next-line no-prototype-builtins
        if (target.hasOwnProperty(key)) {
            // 两种写法都可以实现
            // obj[key] = { ...target[key], ...sources[key] };
            obj[key] = mergeObj(target[key], sources[key]);
        } else {
            // 不存在就直接添加
            obj[key] = sources[key];
        }
    }
    return obj;
};

// 获取当前缩放比例
export const detectZoom = () => {
    var ratio = 0,
        screen = window.screen,
        ua = navigator.userAgent.toLowerCase();

    if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio;
    } else if (~ua.indexOf('msie')) {
        if (screen.deviceXDPI && screen.logicalXDPI) {
            ratio = screen.deviceXDPI / screen.logicalXDPI;
        }
    } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
        ratio = window.outerWidth / window.innerWidth;
    }

    if (ratio) {
        ratio = Math.round(ratio * 100);
    }
    return ratio;
};

/**
 * 根据指定元素获取父级指定类名元素
 * @param {*} element 目标元素
 * @param {*} className 父级类名
 * @returns dom
 */
export const getParents = (element, className) => {
    //dom.getAttribute('class')==dom.className，两者等价
    var returnParentElement = null;
    function getpNode(element, className) {
        try {
            //创建⽗级节点的类数组
            let pClassList = element.parentNode.getAttribute('class');
            let pNode = element.parentNode;
            if (!pClassList) {
                //如果未找到类名数组，就是⽗类⽆类名，则再次递归
                getpNode(pNode, className);
            } else if (pClassList && pClassList.indexOf(className) < 0) {
                //如果⽗类的类名中没有预期类名，则再次递归
                getpNode(pNode, className);
            } else if (pClassList && pClassList.indexOf(className) > -1) {
                returnParentElement = pNode;
            }
        } catch (error) {
            returnParentElement = document.body;
        }
    }
    getpNode(element, className);

    return returnParentElement;
};

/**
 * 根据自定义属性获取DOM
 * @param {*String} tag DOM元素名 - div
 * @param {*} dataAttr 自定义属性
 * @param {*} reg 自定义属性值正则
 */
export function getElementByAttr(tag, dataAttr, reg) {
    var aElements = document.getElementsByTagName(tag);
    var aEle = [];
    for (var i = 0; i < aElements.length; i++) {
        var ele = aElements[i].getAttribute(dataAttr);
        if (reg.test(ele)) {
            aEle.push(aElements[i]);
        }
    }
    return aEle;
}

/**
 * 随机生成英文与数字组合的密码
 * @param {Number} minLen 最小长度
 * @param {Number} maxLen 最大长度
 */
export const randPassword = (minLen, maxLen) => {
    let text = ['abcdefghijklmnopqrstuvwxyz', 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', '1234567890'];
    let rand = function (min, max) {
        return Math.floor(Math.max(min, Math.random() * (max + 1)));
    };
    let len = rand(minLen, maxLen); // 长度为8-16
    let pw = '';
    for (let i = 0; i < len; ++i) {
        let strpos = rand(0, 2);
        let index = rand(0, text[strpos].length - 1);
        pw += text[strpos][index];
    }
    return pw;
};

/**
 * 复制文本
 */
export const copyText = (str) => {
    let input = document.createElement('textarea');
    document.body.append(input);
    input.value = str;
    input.select();
    document.execCommand('Copy');
    document.body.removeChild(input);
};

// 转换单位‘万’，‘亿’+
export function million(value) {
    if (!value) return 0;
    if (typeof value == 'string') {
        value = Number(value);
    }
    let unit = '';
    const k = 10000;
    const sizes = ['', '万', '亿'];
    let i;
    if (value < k) {
        return value;
    }
    i = Math.floor(Math.log(value) / Math.log(k)); // 单位下标
    value = value / Math.pow(k, i);
    value = (value * 100) / 100;
    unit = sizes[i];
    let isFlag = false;
    if (parseInt(value) < parseFloat(value)) {
        //为小数
        value = parseInt(value);
        isFlag = true;
    }
    return value + unit + (isFlag ? '+' : '');
}

// 换算单位
export const convertBytesToSize = (bytes, num = 2) => {
    if (!bytes) return bytes;
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0;
    while (bytes >= 1024 && l < units.length - 1) {
        bytes /= 1024;
        l++;
    }
    bytes = Math.round(bytes * 100) / 100;
    if (bytes % 1 === 0) bytes = bytes.toFixed(num); // 如果是整数，则保留两位小数

    return bytes + units[l];
};

/**
 * 换算单位
 */
export const converUnit = (limit) => {
    if (limit === 0) return limit;
    if (!limit) return '';
    let size = '';
    if (limit < 0.1 * 1024) {
        //如果小于0.1KB转化成B
        size = limit.toFixed(2) + 'B';
    } else if (limit < 0.1 * 1024 * 1024) {
        //如果小于0.1MB转化成KB
        size = (limit / 1024).toFixed(2) + 'KB';
    } else if (limit < 0.1 * 1024 * 1024 * 1024) {
        //如果小于0.1GB转化成MB
        size = (limit / (1024 * 1024)).toFixed(2) + 'MB';
    } else {
        //其他转化成GB
        size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB';
    }

    let sizestr = size + '';
    let len = sizestr.indexOf('.');
    let dec = sizestr.substr(len + 1, 2);
    if (dec == '00') {
        //当小数点后为00时 去掉小数部分
        return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
    }
    return sizestr;
};

/**
 * 时间戳转时间
 * @param {string|number} value
 * @description 时间戳 - 10位/13位
 *
 * 不用moment转换，原因是：中午之后的时间不好区分，例如 ：
 * 时间戳 1004099999000
 * moment: 2001-10-26 08:39:59 / timechange: 2001-10-26 20:39:59
 *
 * moment方法：
 * 13位： moment(numValue).format('YYYY-MM-DD hh:mm:ss')
 * 10位： 方法1：moment(numValue * 1000) 转成13位时间戳
 *        方法2： moment.unix(numValue) 10位时间戳方法
 */
export const timechange = (value) => {
    let numValue = value;
    if (typeof value === 'string') numValue = parseInt(value);
    // 长度只有10，说明只精确到了秒
    if (numValue.toString().length === 10) numValue = numValue * 1000;
    let date = new Date(numValue);
    const Y = date.getFullYear() + '-';
    const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
};

/**
 * json转url参数
 * @param {*Object} obj
 */
export const urlEncode = (obj) => {
    let ret = [];
    for (let i in obj) {
        if (obj[i] !== undefined) {
            ret.push(i + '=' + encodeURIComponent(obj[i]));
        }
    }
    return ret.join('&');
};

/**
 * 计算时间，用年/月/天/小时/分钟前来形容传入时间与今日的距离差
 * @param {string} date 时间 格式：2022-05-24T15:55:56
 */
export const changeTimeFormat = (date) => {
    const minute = 60 * 1000; // 1分钟
    const hour = 60 * minute; // 1小时
    const day = 24 * hour; // 1天
    const month = 31 * day; // 月
    const year = 12 * month; // 年
    if (!date) return '-';
    let diff = new Date().getTime() - new Date(date).getTime();
    let r = 0;
    if (diff > year) {
        r = diff / year;
        return Math.floor(r) + '年前';
    }
    if (diff > month) {
        r = diff / month;
        return Math.floor(r) + '个月前';
    }
    if (diff > day) {
        r = diff / day;
        return Math.floor(r) + '天前';
    }
    if (diff > hour) {
        r = diff / hour;
        return Math.floor(r) + '小时前';
    }
    if (diff > minute) {
        r = diff / minute;
        return Math.floor(r) + '分钟前';
    }
    return '最新报告';
};

/**
 * 仿微信时间处理
 * time:时间格式'2020-01-15'或'2020/01/15'或'2020-01-15 15:00'或'2020/01/15 15:00'
 * yStr:日期拼接格式，默认'/'拼接
 * isHours:是否显示时分，默认不显示
 */
export const updateTimeShow = (time, yStr = '-', isHours = false) => {
    time = time.replace(/-/g, '/'); //苹果真机不支持-连接得日期，所以要转成/链接得日期
    const IN_SECOND = 1000 * 60; //一分钟
    const IN_MINUTE = 1000 * 60 * 60; //一小时
    const IN_DAY = 1000 * 60 * 60 * 24 * 1; //24小时
    const IN_YEAR = 1000 * 60 * 60 * 24 * 30 * 12; //1年

    var realtime = '', //最终返回的时间
        localTime = new Date(), //当前系统时间,世界标准时间
        localDay = localTime.getDate() < 10 ? '0' + localTime.getDate() : localTime.getDate(), //当天系统天数,就是几号
        localMsec = new Date().getTime(), //当前系统时间,毫秒数
        createTime = new Date(time), //传入的时间,世界标准时间
        createMsec = new Date(time).getTime(), //消息创建时间,毫秒数
        diff = localMsec - createMsec, //计算出来得时间戳
        list = new Date(time).toString().split(' '), //将后台返回得时间分割,用于取年月日时分
        year = list[3], //年
        month = getNumberMonth(list[1]), //月
        day = list[2], //日
        hms = list[4]; // 时分秒
    // hhmmss = list[4].split(':'); //[时：分：秒]
    // hourSec = hhmmss[0] + ':' + hhmmss[1]; //时分

    if (diff <= IN_SECOND) {
        //小于1分钟，显示刚刚
        return '刚刚';
    } else if (diff <= IN_MINUTE && diff > IN_SECOND) {
        //小于1小时,显示几分钟前
        return parseInt(diff / IN_SECOND) + '分钟前';
    } else if (diff <= IN_DAY && localDay == day) {
        //当天小于1天,显示时分秒
        return (realtime = hms);
    } else if (diff <= IN_DAY && localDay - day == 1) {
        //昨天当天24小时内,显示月日
        return (realtime = month + yStr + day);
    } else if (diff > IN_DAY && diff <= IN_DAY * 2 && localDay - day <= 1) {
        //大于1天&小于2天,显示昨天
        return '昨天';
    } else if (diff < IN_DAY * 7 && isSameWeek(time)) {
        //本周时间内小于7天,显示周几+时间
        const t = createTime.toString().slice(0, 3);
        switch (t) {
            case 'Mon':
                return '周一 ' + hms;
            case 'Tue':
                return '周二 ' + hms;
            case 'Wed':
                return '周三 ' + hms;
            case 'Thu':
                return '周四 ' + hms;
            case 'Fri':
                return '周五 ' + hms;
            case 'Sat':
                return '周六 ' + hms;
            case 'Sun':
                return '周日 ' + hms;
        }
    } else if (diff < IN_YEAR) {
        //小于1年,显示年月日
        if (isHours) {
            //显示时分秒
            realtime = year + yStr + month + yStr + day + ' ' + hms;
        } else {
            //不显示时分
            realtime = month + yStr + day;
        }
        return realtime;
    } else if (diff > IN_YEAR) {
        //大于一年,显示年月日 时分秒
        return (realtime = year + yStr + month + yStr + day + ' ' + hms);
    }
};

/**
 * 月份转化
 */
function getNumberMonth(month) {
    switch (month) {
        case 'Jan':
            return '01';
        case 'Feb':
            return '02';
        case 'Mar':
            return '03';
        case 'Apr':
            return '04';
        case 'May':
            return '05';
        case 'Jun':
            return '06';
        case 'Jul':
            return '07';
        case 'Aug':
            return '08';
        case 'Sep':
            return '09';
        case 'Oct':
            return '10';
        case 'Nov':
            return '11';
        case 'Dec':
            return '12';
    }
}

/**
   * 判断两个日期是否为同一周
   *  因为1970年1月1 是周4   所以（天数+4）/7 取整 就是周数  如果相同就是同一周反之就不是
  经过测试,是以星期一作为每周的第一天的
   */
function isSameWeek(now) {
    var oneDayTime = 1000 * 60 * 60 * 24;
    var old_count = parseInt(new Date().getTime() / oneDayTime);
    var now_other = parseInt(new Date(now).getTime() / oneDayTime);
    return parseInt((old_count + 4) / 7) == parseInt((now_other + 4) / 7);
}

export const downloadPcapng = async ({ url = '', method = 'get', externalLinks = '' }) => {
    try {
        const downloadFn = (href) => {
            const downloader = document.createElement('a');
            downloader.href = href;

            const oldWindowOnBeforeUnload = window.onbeforeunload;
            window.onbeforeunload = null;

            downloader.click();

            setTimeout(() => {
                window.onbeforeunload = oldWindowOnBeforeUnload;
            }, 1500);
        };
        if (externalLinks) {
            return downloadFn(externalLinks);
        }
        const { data: href, code, message } = await axios({ url, method });

        if (code != 200) {
            message.error(message);
        } else {
            downloadFn(href);
        }
    } catch (error) {
        console.warn(error);
        message.error('下载失败');
    }
};

/**
 * 下载二进制流文件 - Blob格式数据
 * @param {string} method 请求类型
 * @param {object} params 请求参数
 * @param {Function} progressFn 进度条回调方法
 * @param {Function} errFn 错误回调
 */
export const downloadFile = async ({
    url = '',
    file_id = '',
    params,
    method = 'get',
    name,
    progressFn = () => {},
    errFn = () => {},
}) => {
    try {
        const payload = {
            method,
            url: url || `/api/uploadfile/download/${file_id}`,
            responseType: 'blob',
            isNotTips: true,
            onDownloadProgress: (evt) => {
                const obj = {
                    percent: parseInt((evt.loaded / evt.total) * 100),
                    loaded: converUnit(evt.loaded),
                    total: converUnit(evt.total),
                };
                progressFn(obj, evt);
            },
        };
        if (method === 'post') payload.data = params;
        else payload.params = params;

        const res = await axios(payload);
        const disposition = res.headers['content-disposition'] || '';
        const isHave = disposition.indexOf('filename=') !== -1;
        let filename = '';

        // 当名称有中文时，需要decodeURIComponent解码
        if (isHave) filename = disposition.split('filename=')[1].replace(/"/g, '');
        else filename = decodeURIComponent(disposition.split("''")[1]);

        let blob = new Blob([res.data], { type: res.data?.type });
        let csvUrl = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = csvUrl;
        link.setAttribute('download', name || filename);
        link.click();
        // 删除
        window.URL.revokeObjectURL(csvUrl);
    } catch (error) {
        const reader = new FileReader();
        reader.onload = function () {
            const result = JSON.parse(reader.result);
            errFn(result);
            if (result.code === 404) message.error('无权下载！');
            else message.error(result.message);
        };
        reader.readAsText(error);
    }
};
// url参数转对象
export const urlToJson = (url = window.location.href) => {
    // 箭头函数默认传值为当前页面url

    let obj = {},
        index = url.indexOf('?'), // 看url有没有参数
        params = url.substr(index + 1); // 截取url参数部分 id = 1 & type = 2

    if (index != '-1') {
        // 有参数时
        let parr = params.split('&'); // 将参数分割成数组 ["id = 1 ", " type = 2"]
        for (let i of parr) {
            // 遍历数组
            let arr = i.split('='); // 1） i id = 1   arr = [id, 1]  2）i type = 2  arr = [type, 2]
            obj[arr[0]] = arr[1]; // obj[arr[0]] = id, obj.id = 1   obj[arr[0]] = type, obj.type = 2
        }
    }

    return obj;
};

/**
 * 生成图片的base64/压缩图片
 * @param {Object} img new Image生成的对象
 * @param {*string} width 图片宽
 * @param {*string} height 图片稿
 */
export const getCompressBase64 = async (img, type = 'base64', file) => {
    //width、height调用时传入具体像素值，控制大小 ,不传则默认图像大小
    return await new Promise(function (resolve) {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);
        if (type === 'base64') {
            var ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase();
            var dataURL = canvas.toDataURL('image/' + ext);
            resolve(dataURL);
            return dataURL;
        }
        if (type === 'compress') {
            canvas.toBlob(
                (blob) => {
                    const imgFile = new File([blob], file.name, { type: file.type }); // 将blob对象转化为图片文件
                    resolve(imgFile);
                },
                file.type,
                0.6,
            ); //file压缩的图片类型
        }
    });
};

/**
 * 通过绝对路径生成Image对象，来达到生成图片base64/压缩图片
 * @param {String} type 类型
 * @param {String} url 绝对地址
 */
export const getBase64OrCompress = async (type = 'base64', url, file) => {
    return await new Promise(function (resolve) {
        let image = new Image();
        image.crossOrigin = '';
        image.src = url;
        image.onload = async function () {
            const data = await getCompressBase64(image, type, file);
            if (type === 'compress') {
                data.url = url;
                data.uid = file.uid;
            }
            resolve(data);
        };
    });
};

//   关系图数据转层级
export const grapshToThree = (initNode = [], edges = []) => {
    // initNode.reduce((prev, cur) => (prev.includes(cur) ? prev : [...prev, cur]), []);
    // let newArr = [];
    // let nodes = initNode.map((v) => {
    //     v.children = [];
    //     if (v.start) {
    //         newArr.push(v);
    //     }

    //     return v;
    // });

    function getNode(arr, nodeID) {
        let node;
        //查找node节点
        function search(a, ID) {
            a.find((v) => {
                if (v.id == ID) {
                    node = v;
                    return true;
                }
                if (v.children.length) {
                    search(v.children, ID);
                }
            });
        }
        search(arr, nodeID);
        return node;
    }
    initNode.forEach((v) => (v.children = []));
    edges.forEach((edge) => {
        let { source, target } = edge;
        initNode.some((v, i) => {
            if (v.id == target) {
                let sourceNode = getNode(initNode, source);
                if (sourceNode) {
                    initNode.splice(i, 1);
                    sourceNode.children.push(v);
                    return true;
                } else {
                    console.log(edge, v);
                }
            }
        });
    });
    // let edgeSets = new Set();
    // while (edges.length) {
    //     let line = edges.splice(0, 1)[0];
    //     let parentNode = getNode(newArr, line.source);
    //     if (parentNode) {
    //         let childNode = getNode(nodes, line.target);
    //         if (!getNode(newArr, line.target)) {
    //             parentNode.children.push(childNode);
    //         }
    //     } else {
    //         if (edgeSets.has(line)) {
    //             break;
    //         } else {
    //             edges.push(line);
    //             edgeSets.add(line);
    //         }
    //     }
    // }
    return initNode;
};
//获取字符串宽度
export const getTextSize = (text, fontSize = 14, fontFamily = 'Microsoft YaHei') => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.font = `${fontSize}px ${fontFamily}`;
    return ctx.measureText(text).width;
};
//超出指定宽度 删除字符串开头多余部分
export const fittingMaxStr = (str, width, fontSize = 14) => {
    let newStr = str;
    if (getTextSize(str, fontSize) > width) {
        newStr = fittingMaxStr(str.substring(1), width, fontSize);
    }
    return newStr;
};
// 超长文字中间省略
export function captureStr(str, maxWidth, fontsize = 14) {
    let arrStr = String(str).split(''),
        newStr = '',
        leftStr = '',
        rightStr = '',
        curentWidth = 0;
    while (arrStr.length > 0) {
        let start = arrStr.shift();
        let end = arrStr.pop();
        curentWidth += getTextSize(start, fontsize);
        if (curentWidth > maxWidth) {
            newStr =
                leftStr +
                '...' +
                fittingMaxStr(
                    rightStr,
                    getTextSize(rightStr, fontsize) - getTextSize('...', fontsize),
                );
            break;
        } else {
            leftStr += start;
            if (end !== undefined) {
                curentWidth += getTextSize(end, fontsize);
                rightStr = end + rightStr;
            }

            newStr = leftStr + rightStr;
        }
    }

    return newStr;
}
//自定义缩放图片比例
export let modifyImageSize = (path, maxWidth, maxHeight, callback = () => {}) => {
    let image = new Image();
    image.src = path;
    var tempWidth;
    var tempHeight;
    //生成canvas
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    var createw = document.createAttribute('width');
    var createh = document.createAttribute('height');
    image.onload = function () {
        if (image.width > 0 && image.height > 0) {
            if (image.width / image.height >= maxWidth / maxHeight) {
                if (image.width > maxWidth) {
                    tempWidth = maxWidth;
                    tempHeight = (image.height * maxWidth) / image.width;
                } else {
                    tempWidth = image.width;
                    tempHeight = image.height;
                }
            } else {
                if (image.height > maxHeight) {
                    tempHeight = maxHeight;
                    tempWidth = (image.width * maxHeight) / image.height;
                } else {
                    tempWidth = image.width;
                    tempHeight = image.height;
                }
            }
            createw.nodeValue = maxWidth;
            canvas.setAttributeNode(createw);
            if (image.width / image.height > 1) {
                createh.nodeValue = tempHeight;
                canvas.setAttributeNode(createh);
                ctx.drawImage(image, 0, 0, tempWidth, tempHeight);
            } else {
                createh.nodeValue = maxWidth;
                canvas.setAttributeNode(createh);

                ctx.drawImage(image, 0, 0, image.width, image.width, 0, 0, maxWidth, maxWidth);
            }
            callback({ img: canvas, width: tempWidth, height: tempHeight });
        }
    };
    return canvas;
};
//获取base64文件完整路径
export function getFileTypeByBase64(base64) {
    if (!base64) return;
    const pre3Words = base64?.slice(0, 3);
    let fileType = 'data:image/png;base64,';
    switch (pre3Words) {
        case 'iVB':
            fileType = 'data:image/png;base64,';
            break;
        case 'PHN':
            fileType = 'data:image/svg+xml;base64,';
            break;
        case '/9j':
            fileType = 'data:image/jpeg;base64,';
            break;
    }
    return fileType + base64;
}
//防抖
export function debounce(func, delay = 500) {
    let timer = null;

    return function () {
        const context = this;
        const args = arguments;

        clearTimeout(timer);

        timer = setTimeout(function () {
            func.apply(context, args);
        }, delay);
    };
}
//节流
export function throttleImmediateExecution(func, delay) {
    let timerId = null,
        lastArgs = null;

    return function (...args) {
        lastArgs = args;
        if (!timerId) {
            timerId = setTimeout(() => {
                func.apply(this, lastArgs);
                lastArgs = null;
                timerId = null;
            }, delay);
        }
    };
}
/**
 * 16进制颜色转换为rgba颜色
 * @param {*} hex 16进制颜色 #FE9527
 * @param {*} opacity 透明度
 * @returns rgba
 */
export const hexToRgba = (hex, opacity = 1) => {
    if (!hex) return '';
    return (
        'rgba(' +
        parseInt('0x' + hex.slice(1, 3)) +
        ',' +
        parseInt('0x' + hex.slice(3, 5)) +
        ',' +
        parseInt('0x' + hex.slice(5, 7)) +
        ',' +
        opacity +
        ')'
    );
};
//打开关闭全屏
export const FullScreen = (docElm = document.body) => {
    let isFullScreen =
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement;
    if (!isFullScreen) {
        if (docElm.requestFullscreen) {
            docElm.requestFullscreen();
        } else if (docElm.msRequestFullscreen) {
            docElm.msRequestFullscreen();
        } else if (docElm.mozRequestFullScreen) {
            docElm.mozRequestFullScreen();
        } else if (docElm.webkitRequestFullScreen) {
            docElm.webkitRequestFullScreen();
        }
    } else {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
        }
    }
};
//打开新标签页
export const blankTag = (url) => {
    let a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};
// 金额千位分隔符格式化，不四舍五入，向下取整，保留后两位小数
export const formatPrice = (price) => {
    if (!price && price !== 0) return '';
    let value = String(Math.floor(price * 100) / 100);
    let xsd = value.split('.');
    if (xsd.length == 1) {
        value = value + '.00';
    }
    if (xsd.length > 1) {
        if (xsd[1].length < 2) {
            value = value + '0';
        }
    }
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
// 千位分隔符格式化数量
export const formatNum = (value) => String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getVisitorId = async () => {
    const fp = await FingerprintJS.load();
    return await fp.get();
};
export const setVisitorId = async () => {
    let ime = getLocalStorage('ime');
    if (ime) {
        window.ime = ime;
    } else {
        let result = await getVisitorId();
        window.ime = result.visitorId;
        setLocalStorage('ime', result.visitorId);
    }
};
//启动专家服务
export const startExpert = (text) => {
    matomoTrackEvent({ name: '专家服务' });
    window.chatRef?.current?.sendQuestion(text, {
        mode: 'Manual',
        hasOpen: true,
    });
};
// 启动元芳 mode = AI/Manual
export const startAI = (text, options = { mode: 'Manual' }) => {
    window.chatRef?.current?.sendQuestion(text, {
        hasOpen: true,
        ...options,
    });
};
//筛选地区
export const filterArea = (data, line = '-') => {
    let arr = ['country', 'province', 'city', 'district'].map((v) => {
        return data[v];
    });
    return arr.filter(Boolean).join(line);
};
//下载文件
export const downloadTxtFile = (content, filename) => {
    const element = document.createElement('a');
    const file = new Blob([content], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};
