import React from 'react';
import NewModal from '@/components/modal';

export default function Out() {
    return (
        <NewModal
            {...{
                title: '提示',
                width: 480,
                visible: true,
                closable: false,
                centered: true,
                footer: null,
                bodyStyle: {
                    height: '280px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    color: '#545d63',
                },
            }}
        >
            <img src={require('@/assets/images/graph/img/7.png')} width="46" />
            <p>抱歉，您的登录信息已过期</p>
            <p>请您关闭页面后重新打开 [{process.env.title}]</p>
        </NewModal>
    );
}
