export const level = {
    1: '免费版',
    2: '试用版',
    3: '基础版',
    4: '增强版',
    5: '定制化版',
};

export const levelColor = {
    1: '#7e7672',
    2: '#7ea7e4',
    3: '#f4bd81',
    4: '#ad763f',
    5: '#7367b0',
};

export const levelUrl = {
    1: require('@/assets/images/common/level1.svg'),
    2: require('@/assets/images/common/level2.svg'),
    3: require('@/assets/images/common/level3.svg'),
    4: require('@/assets/images/common/level4.svg'),
    5: require('@/assets/images/common/level5.svg'),
};

export const userTypeColor = {
    'role:operate': 'rgb(220, 206, 119,1)',
    'system:admin': 'rgb(204, 134, 134,1)',
    'group:admin': 'rgb(142, 138, 194,1)',
    user: 'rgb(126, 167, 228,1)',
};
