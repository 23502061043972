// const { obtain, defaultPage } = require('@/config');
const routerDynamic = ({ data, configRoutes }: any) => {
    // 无需权限控制页面，可直接设为true
    let authAccess: any = {};

    // let apiMap: any = {
    //     ...obtain,
    //     ...defaultPage,
    // };

    // const authMap: any = (Array.isArray(data) ? data : []).reduce((prev: any, next: any) => {
    //     return { ...prev, [apiMap[next]]: true };
    // }, {});
    function filterRoute(routers: any) {
        routers.forEach((elem: any) => {
            if (!!elem.role) {
                const isHaveRole = !!elem.role && elem.role.some((r: any) => data.includes(r));
                if (isHaveRole) authAccess[elem.path] = true;
            }
            if (!elem.role && elem.path) authAccess[elem.path] = true;
            if (elem.routes && elem.routes.length) filterRoute(elem.routes);
        });
    }

    filterRoute(configRoutes);
    return authAccess;
};
export default routerDynamic;
