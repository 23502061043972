import React, { Component } from 'react';
import { Spin } from 'antd';
import styles from './index.scss';
import loadingJson from './animation/data.json';
import dataJson from './animation/loading.json';
import dataJson2 from './animation/data2.json';
import Lottie from 'react-lottie';

/**
 * loading - 封装了几种加载样式
 */
class PageLoading extends Component {
    static defaultProps = {
        type: 'spin', //rotate / soundbyte / defalut / circle / spin
        height: null,
        isHide: false, //隐藏文字
        width: null,
    };
    constructor(props) {
        super(props);
        this.state = {};
    }

    getLoadingDom = () => {
        const { type, height, isHide, width } = this.props;
        const info = {
            rotate: <div className={styles.rotate}></div>,
            soundbyte: (
                <div className={styles.soundbyte}>
                    <div className={styles.soundbyte_content}></div>
                </div>
            ),
            defalut: (
                <div className={styles.box2}>
                    <div className={styles.loader}></div>
                </div>
            ),
            circle: (
                <div className={styles.box}>
                    <div style={{ height: 30 }}>
                        <div className={styles.circle}></div>
                    </div>
                    {!isHide && <div className={styles.text}>loading...</div>}
                </div>
            ),
            spin: (
                <div className={styles.my_spin} style={{ height }}>
                    <Spin />
                </div>
            ),
            custom: (
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: loadingJson,
                    }}
                    height={height || '100%'}
                    width={width || '100%'}
                    isStopped={false}
                    isPaused={false}
                    isClickToPauseDisabled={true}
                />
            ),
            //绿色
            loading: (
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: dataJson,
                    }}
                    height={height || '100%'}
                    width={width || '100%'}
                    isStopped={false}
                    isPaused={false}
                    isClickToPauseDisabled={true}
                />
            ),
            //棕色
            loading2: (
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: dataJson2,
                    }}
                    height={height || '100%'}
                    width={width || '100%'}
                    isStopped={false}
                    isPaused={false}
                    isClickToPauseDisabled={true}
                />
            ),
        };
        return info[type];
    };
    render() {
        return this.getLoadingDom();
    }
}
export default PageLoading;
