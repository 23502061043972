import request from './axios';

const requestContent = (method, url, params, settingJson) => {
    const obj = {
        method,
        url,
        ...settingJson,
    };
    if (method === 'post') obj.data = params;
    else obj.params = params;
    return obj;
};

/**
 * methods: 请求
 * @param url 请求地址
 * @param params 请求参数
 * @param settingJson 配置参数 （adopt、responseType，onDownloadProgress,onUploadProgress）等等
 * @param adopt 自定义参数，用于axios响应封装时是否判断code码，主要用于下载二进制文件流，返回中只有二进制流没有code码
 */
export const http = {
    get: (url, params, settingJson = {}) => {
        const payload = requestContent('get', url, params, settingJson);
        return request(payload);
    },
    put: (url, params, settingJson = {}) =>
        request(requestContent('put', url, params, settingJson)),
    delete: (url, params, settingJson = {}) =>
        request(requestContent('delete', url, params, settingJson)),
    post: (url, params, settingJson = {}) => {
        const payload = requestContent('post', url, params, settingJson);
        return request(payload);
    },
};

/**
 * 公共请求Api
 * @param {Object} 请求参数，注意传递顺序与http方法内的一致
 */
export const apiInterface = ({ method, ...reset }) => http[method](reset);
