// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/builds/Nosugar-Backend-Applications/dagou/frontend/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/ewmlogin",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login__ewm' */'@/pages/login/ewm.jsx'), loading: LoadingComponent}),
    "title": "钉钉扫码登录回调参数获取",
    "exact": true
  },
  {
    "path": "/bwlogin",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bwlogin__index' */'@/pages/bwlogin/index.jsx'), loading: LoadingComponent}),
    "title": "无糖浏览器登录",
    "exact": true
  },
  {
    "path": "/login",
    "exact": true,
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login__index' */'@/pages/login/index'), loading: LoadingComponent})
  },
  {
    "path": "/docs",
    "exact": true,
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__index' */'@/pages/docs/index'), loading: LoadingComponent}),
    "title": "docs-大狗涉网线索分析平台"
  },
  {
    "path": "/userAgreement",
    "exact": true,
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__userAgreement' */'@/pages/userAgreement'), loading: LoadingComponent}),
    "title": "用户服务协议-大狗涉网线索分析平台"
  },
  {
    "path": "/report",
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})],
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__index' */'@/pages/report/index'), loading: LoadingComponent}),
    "title": "专业报告-大狗涉网线索分析平台",
    "routes": [
      {
        "path": "/report/website",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__website__index' */'@/pages/report/website/index'), loading: LoadingComponent}),
        "title": "网站专业报告-大狗涉网线索分析平台",
        "role": [
          "report:basic"
        ],
        "exact": true
      },
      {
        "path": "/report/apk",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__apk__index' */'@/pages/report/apk/index'), loading: LoadingComponent}),
        "title": "APK专业报告-大狗涉网线索分析平台",
        "role": [
          "apk:view"
        ],
        "exact": true
      },
      {
        "path": "/report/case",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__case__index' */'@/pages/report/case/index'), loading: LoadingComponent}),
        "title": "案情专业报告-大狗涉网线索分析平台",
        "role": [
          "case:view"
        ],
        "exact": true
      }
    ]
  },
  {
    "path": "/graph",
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})],
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__controlCenter__index' */'@/pages/controlCenter/index'), loading: LoadingComponent}),
    "title": "线索图谱-大狗涉网线索分析平台",
    "hideMenu": true,
    "exact": true
  },
  {
    "path": "/apkSimulate",
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})],
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__apkSimulate__index' */'@/pages/apkSimulate/index'), loading: LoadingComponent}),
    "title": "云真机操作台-大狗涉网线索分析平台",
    "hideMenu": true,
    "exact": true
  },
  {
    "path": "/course",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__course__index' */'@/pages/course/index'), loading: LoadingComponent}),
    "title": "使用教程-大狗涉网线索分析平台",
    "hideMenu": true,
    "exact": true
  },
  {
    "path": "/details",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sourceCode__index' */'@/pages/sourceCode/index'), loading: LoadingComponent}),
    "title": "代码详情-大狗涉网线索分析平台",
    "hideMenu": true,
    "exact": true
  },
  {
    "path": "/globalStatistics",
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})],
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__globalStatistics' */'@/pages/globalStatistics'), loading: LoadingComponent}),
    "title": "全球地图可视统计-大狗涉网线索分析平台",
    "hideGlobalBtn": true,
    "exact": true
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layout__index' */'@/layout/index'), loading: LoadingComponent}),
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})],
    "routes": [
      {
        "path": "/",
        "redirect": "/search",
        "hideMenu": true,
        "exact": true
      },
      {
        "path": "/search",
        "title": "线索研判-大狗涉网线索分析平台",
        "role": [
          "clue"
        ],
        "routes": [
          {
            "path": "/search",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__eyes__index' */'@/pages/eyes/index'), loading: LoadingComponent}),
            "title": "线索研判-大狗涉网线索分析平台",
            "hideMenu": true,
            "hideBreadcrumb": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/history",
        "role": [
          "task"
        ],
        "title": "研判记录-大狗涉网线索分析平台",
        "routes": [
          {
            "path": "/history",
            "redirect": "/history/website",
            "exact": true
          },
          {
            "path": "/history/website",
            "title": "网站研判记录-大狗涉网线索分析平台",
            "icon": "url",
            "routes": [
              {
                "path": "/history/website",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__history__index' */'@/pages/history/index'), loading: LoadingComponent}),
                "title": "网站研判记录-大狗涉网线索分析平台",
                "hideMenu": true,
                "hideBreadcrumb": true,
                "exact": true
              },
              {
                "path": "/history/website/detail",
                "title": "任务组详情-大狗涉网线索分析平台",
                "hideMenu": true,
                "routes": [
                  {
                    "path": "/history/website/detail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__history__website__detail' */'@/pages/history/website/detail'), loading: LoadingComponent}),
                    "title": "任务组详情-大狗涉网线索分析平台",
                    "hideMenu": true,
                    "hideBreadcrumb": true,
                    "exact": true
                  },
                  {
                    "path": "/history/website/detail/groupReport",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__history__groupReport' */'@/pages/history/groupReport'), loading: LoadingComponent}),
                    "title": "组报告-大狗涉网线索分析平台",
                    "role": [
                      "group:report:view"
                    ],
                    "hideMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/history/website/detail/examine",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__history__examine' */'@/pages/history/examine'), loading: LoadingComponent}),
                    "title": "样本嗅察-大狗涉网线索分析平台",
                    "role": [
                      "record:verify"
                    ],
                    "hideMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/history/website/detail/clue",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__history__website__judgeClues' */'@/pages/history/website/judgeClues'), loading: LoadingComponent}),
                    "title": "调证线索-大狗涉网线索分析平台",
                    "hideMenu": true,
                    "exact": true
                  }
                ]
              },
              {
                "path": "/history/website/groupReport",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__history__groupReport' */'@/pages/history/groupReport'), loading: LoadingComponent}),
                "title": "组报告-大狗涉网线索分析平台",
                "role": [
                  "group:report:view"
                ],
                "hideMenu": true,
                "exact": true
              }
            ]
          },
          {
            "path": "/history/case",
            "title": "案情研判记录-大狗涉网线索分析平台",
            "role": [
              "case:view"
            ],
            "icon": "case",
            "routes": [
              {
                "path": "/history/case",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__history__index' */'@/pages/history/index'), loading: LoadingComponent}),
                "title": "案情研判记录-大狗涉网线索分析平台",
                "hideMenu": true,
                "hideBreadcrumb": true,
                "exact": true
              },
              {
                "path": "/history/case/detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__history__case__detail' */'@/pages/history/case/detail'), loading: LoadingComponent}),
                "title": "案情详情-大狗涉网线索分析平台",
                "role": [
                  "case:view"
                ],
                "hideMenu": true,
                "exact": true
              }
            ]
          },
          {
            "path": "/history/apk",
            "title": "APK研判记录-大狗涉网线索分析平台",
            "role": [
              "apk:view"
            ],
            "icon": "apk",
            "routes": [
              {
                "path": "/history/apk",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__history__index' */'@/pages/history/index'), loading: LoadingComponent}),
                "title": "APK研判记录-大狗涉网线索分析平台",
                "hideMenu": true,
                "hideBreadcrumb": true,
                "exact": true
              },
              {
                "path": "/history/apk/detail",
                "title": "任务组详情-大狗涉网线索分析平台",
                "role": [
                  "apk:view"
                ],
                "hideMenu": true,
                "routes": [
                  {
                    "path": "/history/apk/detail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__history__apk__detail' */'@/pages/history/apk/detail'), loading: LoadingComponent}),
                    "title": "任务组详情-大狗涉网线索分析平台",
                    "hideMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/history/apk/detail/clue",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__history__apk__judgeClues' */'@/pages/history/apk/judgeClues'), loading: LoadingComponent}),
                    "title": "调证线索-大狗涉网线索分析平台",
                    "hideMenu": true,
                    "exact": true
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "path": "/strike",
        "role": [
          "warning"
        ],
        "title": "线索中心-大狗涉网线索分析平台",
        "routes": [
          {
            "path": "/strike",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__strike__main__index' */'@/pages/strike/main/index'), loading: LoadingComponent}),
            "title": "线索中心-大狗涉网线索分析平台",
            "hideMenu": true,
            "hideBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/strike/clue",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__strike__result' */'@/pages/strike/result'), loading: LoadingComponent}),
            "title": "打击线索-大狗涉网线索分析平台",
            "hideMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/dataCenter",
        "role": [
          "centre"
        ],
        "title": "数据中心-大狗涉网线索分析平台",
        "routes": [
          {
            "path": "/dataCenter/dataReport",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dataCenter__dataReport' */'@/pages/dataCenter/dataReport'), loading: LoadingComponent}),
            "role": [
              "centre:view"
            ],
            "title": "数据报告管理-大狗涉网线索分析平台",
            "icon": "counteraction",
            "exact": true
          },
          {
            "path": "/dataCenter/dataService",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dataCenter__dataService' */'@/pages/dataCenter/dataService'), loading: LoadingComponent}),
            "role": [
              "export:view"
            ],
            "title": "系统导出文件-大狗涉网线索分析平台",
            "icon": "dataService",
            "exact": true
          },
          {
            "path": "/dataCenter/dispatchTask",
            "role": [
              "record:verify",
              "record:confirm",
              "record:verify:division",
              "record:confirm:division"
            ],
            "title": "样本分发/处理-大狗涉网线索分析平台",
            "icon": "dispatchTask",
            "routes": [
              {
                "path": "/dataCenter/dispatchTask",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dataCenter__dispatchTask' */'@/pages/dataCenter/dispatchTask'), loading: LoadingComponent}),
                "title": "样本分发/处理-大狗涉网线索分析平台",
                "hideMenu": true,
                "hideBreadcrumb": true,
                "exact": true
              },
              {
                "path": "/dataCenter/dispatchTask/againExamine",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dataCenter__againExamine' */'@/pages/dataCenter/againExamine'), loading: LoadingComponent}),
                "title": "涉诈样本审核-大狗涉网线索分析平台",
                "hideMenu": true,
                "exact": true
              },
              {
                "path": "/dataCenter/dispatchTask/examine",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__history__examine' */'@/pages/history/examine'), loading: LoadingComponent}),
                "title": "样本嗅察-大狗涉网线索分析平台",
                "role": [
                  "record:verify"
                ],
                "hideMenu": true,
                "exact": true
              }
            ]
          },
          {
            "path": "/dataCenter/population",
            "title": "族群样本库管理-大狗涉网线索分析平台",
            "role": [
              "archive:image:operate"
            ],
            "icon": "population",
            "routes": [
              {
                "path": "/dataCenter/population",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dataCenter__population' */'@/pages/dataCenter/population'), loading: LoadingComponent}),
                "title": "族群样本库管理-大狗涉网线索分析平台",
                "hideMenu": true,
                "hideBreadcrumb": true,
                "exact": true
              },
              {
                "path": "/dataCenter/population/detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dataCenter__populationDetail' */'@/pages/dataCenter/populationDetail'), loading: LoadingComponent}),
                "title": "族群样本库详情-大狗涉网线索分析平台",
                "role": [
                  "archive:image:operate"
                ],
                "hideMenu": true,
                "exact": true
              }
            ]
          },
          {
            "path": "/dataCenter/operate",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operate' */'@/pages/operate'), loading: LoadingComponent}),
            "title": "运营统计-大狗涉网线索分析平台",
            "role": [
              "summary:admin"
            ],
            "icon": "operate",
            "search": "pageType=super",
            "exact": true
          }
        ]
      },
      {
        "path": "/monitorCenter",
        "title": "监控中心-大狗涉网线索分析平台",
        "role": [
          "monitor"
        ],
        "routes": [
          {
            "path": "/monitorCenter",
            "redirect": "/monitorCenter/asnManage",
            "exact": true
          },
          {
            "path": "/monitorCenter/asnManage",
            "title": "ASN监控管理-大狗涉网线索分析平台",
            "role": [
              "asn:operate",
              "asn:view"
            ],
            "icon": "manage",
            "routes": [
              {
                "path": "/monitorCenter/asnManage",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__monitorCenter__asnManage__index' */'@/pages/monitorCenter/asnManage/index'), loading: LoadingComponent}),
                "title": "ASN监控管理-大狗涉网线索分析平台",
                "exact": true
              },
              {
                "path": "/monitorCenter/asnManage/details",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__monitorCenter__asnDetail__index' */'@/pages/monitorCenter/asnDetail/index'), loading: LoadingComponent}),
                "title": "详情-大狗涉网线索分析平台",
                "exact": true
              }
            ]
          },
          {
            "path": "/monitorCenter/monitor",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__monitorCenter__monitor' */'@/pages/monitorCenter/monitor'), loading: LoadingComponent}),
            "title": "系统运行监控-大狗涉网线索分析平台",
            "role": [
              "watching:monitor:view"
            ],
            "icon": "monitor",
            "exact": true
          }
        ]
      },
      {
        "path": "/task",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__task__index' */'@/pages/task/index'), loading: LoadingComponent}),
        "role": [
          "issue"
        ],
        "title": "任务工单-大狗涉网线索分析平台",
        "exact": true
      },
      {
        "path": "/laboratory",
        "title": "指纹实验室-大狗涉网线索分析平台",
        "role": [
          "fingerprint"
        ],
        "routes": [
          {
            "path": "/laboratory",
            "redirect": "/laboratory/suspectedHandle",
            "exact": true
          },
          {
            "path": "/laboratory/suspectedHandle",
            "title": "疑似族群处理-大狗涉网线索分析平台",
            "icon": "suspectedHandle",
            "routes": [
              {
                "path": "/laboratory/suspectedHandle",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__suspectedHandle__index' */'@/pages/suspectedHandle/index'), loading: LoadingComponent}),
                "title": "疑似族群处理-大狗涉网线索分析平台",
                "hideMenu": true,
                "hideBreadcrumb": true,
                "exact": true
              },
              {
                "path": "/laboratory/suspectedHandle/details",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__suspectedHandle__components__details' */'@/pages/suspectedHandle/components/details'), loading: LoadingComponent}),
                "title": "详情-大狗涉网线索分析平台",
                "hideMenu": true,
                "exact": true
              }
            ]
          },
          {
            "path": "/laboratory/fingerprint",
            "role": [
              "fingerprint:view"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fingerprint' */'@/pages/fingerprint'), loading: LoadingComponent}),
            "title": "样本指纹管理-大狗涉网线索分析平台",
            "icon": "fingerprint",
            "exact": true
          }
        ]
      },
      {
        "path": "/setting",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__index' */'@/pages/setting/index'), loading: LoadingComponent}),
        "title": "系统设置-大狗涉网线索分析平台",
        "role": [
          "settings"
        ],
        "hideMenu": true,
        "routes": [
          {
            "path": "/setting/user",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__user__index' */'@/pages/setting/user/index'), loading: LoadingComponent}),
            "role": [
              "group:admin"
            ],
            "title": "用户管理-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/setting/group",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__user__index' */'@/pages/setting/user/index'), loading: LoadingComponent}),
            "role": [
              "role:operate"
            ],
            "title": "分组管理-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/setting/role",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__user__index' */'@/pages/setting/user/index'), loading: LoadingComponent}),
            "role": [
              "role:operate"
            ],
            "title": "权限范围-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/setting/fraudType",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__fraudType' */'@/pages/setting/fraudType'), loading: LoadingComponent}),
            "role": [
              "fraud:type:operate"
            ],
            "title": "诈骗类型管理-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/setting/whiteList",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__whiteList' */'@/pages/setting/whiteList'), loading: LoadingComponent}),
            "role": [
              "whitelist:operate"
            ],
            "title": "白名单管理-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/setting/labelmanagement",
            "role": [
              "system:label:operate"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__labelManagement' */'@/pages/setting/labelManagement'), loading: LoadingComponent}),
            "title": "标签管理-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/setting/backmanagement",
            "role": [
              "job:view"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__backManagement' */'@/pages/setting/backManagement'), loading: LoadingComponent}),
            "title": "后台任务管理-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/setting/template",
            "role": [
              "group:admin"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__template' */'@/pages/setting/template'), loading: LoadingComponent}),
            "title": "功能模板-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/setting/fingerpost",
            "role": [
              "judge:view"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__fingerpost' */'@/pages/setting/fingerpost'), loading: LoadingComponent}),
            "title": "调证指南-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/setting/quota",
            "role": [
              "group:admin"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__quota' */'@/pages/setting/quota'), loading: LoadingComponent}),
            "title": "额度管理-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/setting/voidmanagement",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__voidmanagement' */'@/pages/setting/voidmanagement'), loading: LoadingComponent}),
            "title": "无效扩展点管理-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/setting/frida",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__frida' */'@/pages/setting/frida'), loading: LoadingComponent}),
            "title": "Frida内置脚本管理-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "role": [
              "app_script:operate"
            ],
            "exact": true
          },
          {
            "path": "/setting/log",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__log' */'@/pages/setting/log'), loading: LoadingComponent}),
            "title": "系统操作日志-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "role": [
              "role:operate"
            ],
            "exact": true
          },
          {
            "path": "/setting/notification",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__notification' */'@/pages/setting/notification'), loading: LoadingComponent}),
            "title": "系统通知管理-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "role": [
              "system_notify:operate"
            ],
            "exact": true
          },
          {
            "path": "/setting/course",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__course' */'@/pages/setting/course'), loading: LoadingComponent}),
            "title": "使用教程管理-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/personal",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__personal__index' */'@/pages/personal/index'), loading: LoadingComponent}),
        "title": "个人中心-大狗涉网线索分析平台",
        "hideMenu": true,
        "hideBreadcrumb": true,
        "routes": [
          {
            "path": "/personal/account",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__personal__account' */'@/pages/personal/account'), loading: LoadingComponent}),
            "title": "账户信息-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/personal/quota",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__personal__quota' */'@/pages/personal/quota'), loading: LoadingComponent}),
            "title": "额度信息-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/personal/record",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__personal__record' */'@/pages/personal/record'), loading: LoadingComponent}),
            "title": "记录与统计-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/personal/myFile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__personal__myFile' */'@/pages/personal/myFile'), loading: LoadingComponent}),
            "title": "我的文件-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/personal/changePhone",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__personal__changePhone' */'@/pages/personal/changePhone'), loading: LoadingComponent}),
            "title": "修改手机号-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/personal/changePassword",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__personal__changePassword' */'@/pages/personal/changePassword'), loading: LoadingComponent}),
            "title": "修改密码-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/personal/loginLog",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__personal__loginLog' */'@/pages/personal/loginLog'), loading: LoadingComponent}),
            "title": "登录日志-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/personal/deviceManagement",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__personal__deviceManagement' */'@/pages/personal/deviceManagement'), loading: LoadingComponent}),
            "title": "在线设备管理-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/personal/twoFactorAuth",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__personal__twoFactorAuth' */'@/pages/personal/twoFactorAuth'), loading: LoadingComponent}),
            "title": "双重身份验证-大狗涉网线索分析平台",
            "hideBreadcrumb": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/exportFile",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dataCenter__dataService' */'@/pages/dataCenter/dataService'), loading: LoadingComponent}),
        "title": "我的文件-大狗涉网线索分析平台",
        "hideMenu": true,
        "hideBreadcrumb": true,
        "exact": true
      },
      {
        "path": "/myteam",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__myTeam__index' */'@/pages/myTeam/index'), loading: LoadingComponent}),
        "role": [
          "system:admin",
          "role:operate",
          "group:admin"
        ],
        "title": "我的团队-大狗涉网线索分析平台",
        "hideMenu": true,
        "hideBreadcrumb": true,
        "exact": true
      },
      {
        "path": "/personpage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__personPage__index' */'@/pages/personPage/index'), loading: LoadingComponent}),
        "title": "个人主页-大狗涉网线索分析平台",
        "hideMenu": true,
        "exact": true
      },
      {
        "path": "/InfoNotify",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__InfoNotify__index' */'@/pages/InfoNotify/index'), loading: LoadingComponent}),
        "title": "通知中心-大狗涉网线索分析平台",
        "hideMenu": true,
        "hideBreadcrumb": true,
        "exact": true
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404__index' */'@/pages/404/index'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
