export default {
    301: {
        m: '用户未登录',
        d: '用户未登录，请登录',
        i: 'warning',
    },
    400: {
        m: '请求错误',
        d: '请求错误，用户参数导致非预期错误',
        i: 'warning',
    },
    401: {
        m: '无权限访问',
        d: '用户验证信息有误或已过期，请重新登录',
        i: 'error',
    },
    403: {
        m: '权限验证失败',
        d: 'Token 或用户名密码错误等，权限检查失败',
        i: 'error',
    },
    404: {
        m: '数据缺失',
        d: '数据缺失',
        i: 'error',
    },
    405: {
        m: '请求类型错误',
        d: '请求方式不被允许, POST，PUT 等',
        i: 'error',
    },
    406: {
        m: '格式错误',
        d: '用户指定所需要的格式如json与可返回的格式不一致',
        i: 'error',
    },
    410: {
        m: '禁止访问',
        d: '数据禁止访问, 记录处理不可访问或删除状态',
        i: 'error',
    },
    422: {
        m: '参数错误',
        d: '参数验证失败, 表单等用户传递的数据验证失败',
        i: 'error',
    },
    423: {
        m: '记录已存在',
        d: '记录已存在, 相同记录已存在于数据中',
        i: 'error',
    },
    424: {
        m: '没有更多记录',
        d: ' 没有更多的数据',
        i: 'error',
    },
    425: {
        m: '数据已过期',
        d: '数据已过期',
        i: 'error',
    },
    500: {
        m: '未知错误！',
        d: '系统出现未知错误，请稍后重试。',
        i: 'error',
    },
    501: {
        m: '账号未绑定！',
        d: '账号未绑定，请绑定后重试。',
        i: 'error',
    },
    502: {
        m: '双重身份认证',
        d: '已开启双重身份认证，请先输入验证码。',
        i: 'warning',
    },
    510: {
        m: '处理超时',
        d: '系统处理超时，请稍后重试。',
        i: 'error',
    },
    511: {
        m: '请求失败',
        d: '系统出现未知错误，请稍后重试。',
        i: 'error',
    },
    512: {
        m: '上传文件失败',
        d: '上传文件失败',
        i: 'error',
    },
    516: {
        m: '查询失败',
        d: '数据量过大',
        i: 'error',
    },
    700: {
        m: '连接失败！',
        d: '后端服务器连接失败',
        i: 'error',
    },
    800: {
        m: '网络异常',
        d: '用户网络异常！',
        i: 'error',
    },
    ECONNABORTED: {
        m: '连接超时！',
        d: '系统处理超时，请稍后重试',
        i: 'error',
    },
};
