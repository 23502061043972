import { useState, createContext, useRef, useEffect } from 'react';
import { Form, message } from 'antd';
import { http } from '@/utils/httpApi';
import { idCardReg, bankCardReg, phoneReg, telephoneReg } from '@/utils/common';
import { matomoTrackEvent } from '@/utils/matomoInstance';
export const SearchContext = createContext();

const InputModel = () => {
    const [form] = Form.useForm();
    const dataRef = useRef();
    let [rowDetail, setRowDetail] = useState({});
    const [oldData, setOldData] = useState({});
    const [isFocus, setIsFocus] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [inputError, setInputError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchRes, setSearchRes] = useState({});
    const [isSearch, setIsSearch] = useState(false);
    const [isNoData, setIsNoData] = useState(true);

    useEffect(() => {
        window.addEventListener('click', () => {
            setIsOpen(false);
            setIsFocus(false);
        });
    }, []);

    const clearModel = () => {
        setRowDetail({});
        setIsFocus(false);
        setIsOpen(false);
        setInputError(false);
        setLoading(false);
        setSearchRes({});
        setIsSearch(false);
        setIsNoData(true);
        form.setFieldsValue({ data: '' });
    };

    const verificationFn = (type, value) => {
        let isFlag = true;
        switch (type) {
            case 'id_card':
                isFlag = idCardReg.test(value);
                break;
            case 'bank_card':
                isFlag = bankCardReg.test(value);
                break;
            case 'victim_phone':
                isFlag = phoneReg.test(value);
                break;
            case 'registrant_phone':
                isFlag = telephoneReg.test(value) || phoneReg.test(value);
                break;
            default:
                isFlag = true;
                break;
        }
        return isFlag;
    };

    const onFinish = async (row = {}) => {
        try {
            setInputError(false);
            if (loading) return Promise.reject('正在加载中');
            let values = await form.validateFields();
            if (!values.data) {
                message.warning('输入的内容 不可为空！');
                return Promise.reject('内容不可为空');
            }

            values.data = values.data?.trim();
            if (JSON.stringify(row) !== '{}') {
                rowDetail = { ...row };
            }
            rowDetail.data = values.data;
            setRowDetail({ ...rowDetail });

            setLoading(true);
            const params = {
                data: values.data,
            };
            if (rowDetail.type) {
                params.data_type = rowDetail.type;
            }
            matomoTrackEvent({
                name: '线索研判搜索次数',
            });
            let res = await http.post('/api/site/map/full/search', { page_size: 10, ...params });

            const { data, suggest } = res;
            setOldData({ ...rowDetail });
            setSearchRes(res);
            if (data.length || suggest.length) {
                setIsNoData(false);
            } else {
                setIsNoData(true);
            }
            setIsSearch(true);
            setIsFocus(false);
            setLoading(false);
            return res;
        } catch (error) {
            setLoading(false);
            return Promise.reject(error);
        }
    };

    return {
        form,
        dataRef,
        rowDetail,
        setRowDetail,
        isFocus,
        setIsFocus,
        isOpen,
        setIsOpen,
        inputError,
        setInputError,
        loading,
        setLoading,
        searchRes,
        isSearch,
        setIsSearch,
        onFinish,
        isNoData,
        setIsNoData,
        clearModel,
        verificationFn,
        oldData,
    };
};
export default InputModel;
